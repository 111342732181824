/*
 * @Author: WYY 
 * @Description: 
 * @ModuleName:   退出学习
 * @Date: 2019-01-11 17:27:20 
 * @Last Modified by: WYY
 * @Last Modified time: 2019-01-14 11:30:44
 */
<template>
    <el-dialog :visible="show_" :before-close='Close' title='Hint' custom-class='TanChuang'>
        <div class="T_body">
            <p> Do you want to quit course? </p>
        </div>
        <div class="T_footer">
            <div class="confirm" @click="Confirm">Yes</div>
            <div class="cancel" @click='Close'>No</div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props:['show'],
        data(){
            return {
                show_:this.show,
            }
        },
        created() {

        },
        watch: {
            show:function(v){
                this.show_ = v;
            }
        },
        methods: {
            Close(){
                this.$store.commit('setitem',{name:'quitCourseShow',item:false});
            },
            Confirm(){
                this.OutSide();
                console.log("关闭：",this.$ipcRenderer);
                // if(this.$ipcRenderer){
                //     sessionStorage.clear()
                //     localStorage.clear()
                //     this.$ipcRenderer.send("exit");
                // }
            }
        },
        computed: {
        },
    }
</script>

<style scoped>

</style>