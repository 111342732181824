import Url from '../../util'
export default {
    state: {
        IP_URL: 'http://192.168.1.6:3011',
        begin:false, // 判断是否开始学习
        datacomplate:0, //判断 数据获取完成
        menulist:[],
        checkid:'', // 选中章节 id
        outerId:'', // 选中章节 最外层id
        dataStructure:null, // menu data structure
        dataLayer:null, // menu list layer
        MenuSwitch:0,  // 菜单开关
        TitleType:[   // 题目类型
            {
                typeid:'04F485861F9D4BCC9FDED30501EC0314', // 多问选择(复) 
                path:'/home/long',  
            },
            {
                typeid:'15C5EAC397A248B2B6369160F86B4ECC', // 判断题 （单） 
                path:'/home/judgment',  
            },
            {
                typeid:'1C937068E7834C0F8E757943D1B96634', // 发音练习 （单）
                path:'/home/Pronunciation', 
            },
            {
                typeid:'3CA4F712ABBF4FF39A676A3011F26615', // 飞行模拟 （单）
                path:'/home/FlightSimulation', 
            },
            {
                typeid:'50A26ECF8CC84454945641071A21C504', // 单词点读 （单） 
                path:'/home/ReadWord',  
            },
            {
                typeid:'0f9fac2b1c5d11edb9b1000c292d34a1', // 单词点读 （二字码） 
                path:'/home/ReadWord1',  
            },
            {
                typeid:'109ab36d1c5c11edb9b1000c292d34a1', // 单词点读 （速记练习） 
                path:'/home/ReadWord2',  
            },
            {
                typeid:'cfa21ca21c5c11edb9b1000c292d34a1', // 单词点读 （缩略语） 
                path:'/home/ReadWord2',  
            },
            {
                typeid:'6E019A673B4147F68F7A599C46F304B1', // 单问选择（单） 
                path:'/home/short', 
            },
            {
                typeid:'93169DC3FD6D4BADA4DE0D02AD0567E6', // 人机面试（OPI）（复） 
                path:'/home/Opi',   
            },
            {
                typeid:'7B1035AB1A0F11ED84FD0261651503D4', // 翻译题 
                path:'/home/OpiNew',   
            },
            {
                typeid:'CC49F712751D452799FD9A9DD564F698', // 模拟练习  （复） 
                path:'/home/SimulationExercise',   
            },
            {
                typeid:'E5E6AA5B78E746A59FDC89A24217B148', // 故事复述 （单） 
                path:'/home/RepeatStory',   
            },
            {
                typeid:'EFDCCE2E0ADD403B897DCA97679CC5F5', // 简要回答 （单）
                path:'/home/BriefAnswer', 
            },
            {
                typeid:'F33119E8748E4C86A0AB823118049FB6', // 单问选择  短文简 
                path:'/home/SimpleShort',   
            },
            {
                typeid:'1DE35A765E694B47A67B9E5520B798CA', // 短文复述  
                path:'/home/ShortRetelling', 
            },
            // 新增题型
            {
                typeid:'061c8b6eedf511ebb016000c29e3bb40', // 选择题（图）
                path:'/home/SelectOnePicture',
            },
            {
                typeid:'e52393a4edf411ebb016000c29e3bb40', // 词汇辨析
                path:'/home/LexicalDiscrimination', 
            },
            {
                typeid:'ebfaae88edf411ebb016000c29e3bb40', // 用语辨析
                path:'/home/LanguageDiscrimination',    
            },
            {
                typeid:'f601087dedf411ebb016000c29e3bb40', // 案例解析
                path:'/home/CaseAnalysis',  
            }
        ],
        // 当前 模式
        Pattern:'',
        // 闯关模式 最大
        MaxPattern:'',
        // 录音
        RecordBtn:false,
        // 课程ID
        curriculum:'',
        // 剩余时间  秒为 单位
        RemainingTime:0,
        RemaTime:'',
        // 退出学习监听
        WithdrawalStudy:0,
        // 测试
        Testing:null,
        CheckShow:true,
        AudioUrl:Url.AudioUrl,
        AudioUrlPublic:Url.AudioUrlPublic,
        ImgUrl:Url.ImgUrl,
        headUrl:'', //头像
        queryConfig:{},//名称和图片配置
        UploadAudio:Url.UploadAudio, // 测试
        usertype:'',  // 判断是否为包年用户  1 是  0 不是
        isJudgment:'', // 判断是否需要判分  1 需要 0 不需要
        RoleIsJudgment: '',// 判断是否需要判分  1 需要 0 不需要
        TargetItem:{},


        timeOutShow:false, // 时间结束 弹窗
        threeTimeShow:false,// 剩余 3 分钟
        quitCourseShow:false, // 退出学习 弹窗
        reportShow:false, // 测试报告  弹窗
        outStudyShow:false, // 8分钟停止学习 弹窗
        continueStudy:0,
        reportData:{}, // 测试报告 数据
        testPause:true, // 测试模式 暂停  默认进行中 true

        // text note key
        threeActive:'',
        threeList:[],
        searchActive: false,
        hotActive: false,
        wordsActive: false,
        menushow: false,
        targetData: {}
    },
    mutations: {
        setContinueStudy:function(state){
            state.outStudyShow = false;
            state.continueStudy++;
        },
        setRecordBtn:function(state,data){
            state.RecordBtn = data;
            state.MenuSwitch++;
        },
        setbegin:function(state,data){
            state.begin = data;
        },
        setitem:function(state,data){
            state[data.name] = data.item;
        },
        setAdd:function(state,data){
            state[data]++;
        },
        setmenulist(state,data){
            state.menulist = data;
        },
        setdatacomplate(state,data){
            state.datacomplate++;
        },
        setRemainingTime(state,data){
            state.RemainingTime--;
        },
        setWithdrawalStudy(state,data){
            state.WithdrawalStudy++;
        }
    },
    actions: {

    },
    getters: {

    },
}
