<template>
    <div class="Home">
        <check></check>
        <div :class='(CheckShow&&Testing)?"Blur":""' class="main">
            <Aside v-show="!isHomePage" ></Aside>
            <router-view class="main-content"></router-view>
        </div>
        <TimeOut :show.sync='timeOutShow' @Close='timeOutClose'/>
        <ThreeMinutes :show.sync='threeTimeShow'/>
        <QuitCourse :show.sync='quitCourseShow'/>
        <Report  :show.sync='reportShow'/>
        <StopStudy :show.sync='outStudyShow'/>

    </div>
</template>

<script>
import Aside from './Aside'
import check from './Check'
import TimeOut from '../components/TimeOut'
import ThreeMinutes from '../components/ThreeMinutes'
import QuitCourse from '../components/QuitCourse'
import Report from '../components/Report'
import StopStudy from '../components/stopStudy'
export default {
    name: "home",
    data(){
        return{
            isHomePage:false
        }
    },
    components:{
        Aside,check,TimeOut,ThreeMinutes,QuitCourse,Report,StopStudy
    },
    computed:{
        outStudyShow:function(){
            return this.$store.state.common.outStudyShow;
        },
        reportShow:function(){
            return this.$store.state.common.reportShow;
        },
        quitCourseShow:function(){
            return this.$store.state.common.quitCourseShow;
        },
        timeOutShow:function(){
            return this.$store.state.common.timeOutShow;
        },
        threeTimeShow:function(){
            return this.$store.state.common.threeTimeShow;
        },
        Testing:function(){
            return this.$store.state.common.Testing;
        },
        CheckShow:function(){
            return this.$store.state.common.CheckShow;
        },

    },
    beforeUpdate(){
        this.changeShowAside()
    },
    created() {
        
        // sessionStorage.setItem('ROUTERPARAMS',this.$route.redirectedFrom.substr(1))
        // 第一次加载首页，保存地址
        let SecondLoad = sessionStorage.getItem('SecondLoad')
        if( !SecondLoad){
            sessionStorage.setItem('ROUTERPARAMS',this.$router.history._startLocation)
            sessionStorage.setItem('SecondLoad',true)
        }

        let token = this.getQueryString('token');
        if(!!token){
            sessionStorage.setItem('token',token);
        }
        let ExamType = this.getQueryString('ExamType');
        sessionStorage.setItem('ExamType',ExamType);
        let trainClassId = this.getQueryString('trainClassId') || '';
        sessionStorage.setItem('trainClassId',trainClassId);
        let userId = this.getQueryString('userId') || '';
        sessionStorage.setItem('userId',userId);
        let headUrl = this.getQueryString('headUrl') || '';
        this.$store.commit('setitem',{'name':'headUrl','item':headUrl});

        window.addEventListener('beforeunload',e=>{
            let c = sessionStorage.getItem('begin');
            if(!!c){
                // this.AddStudyLog()
            }
        })
        this.changeShowAside()

        this.$store.commit('setitem',{'name':'searchActive','item':false});
        this.$store.commit('setitem',{'name':'wordsActive','item':false});

    },
    methods:{
        timeOutClose(){ // time out
            window.close();
        },
        getQueryString(name) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
            var r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return null;
        },
        changeShowAside(){
            // this.isHomePage = (this.$router.history.current.path == "/home")
            // 考试模式和首页不显示左侧菜单
            let isExam = true
            if (sessionStorage.ExamType == "null" || sessionStorage.ExamType == "undefined"  ){
                isExam = false
            }
            if( this.$router.history.current.path == "/home"  || isExam){
                this.isHomePage = true
            }else{
                this.isHomePage = false
            }
        }
    },
};
</script>
<style lang="less" scoped>
.Home{
    width: 100%;
    height: 100%;
    .main{
        width: 100%;
        height: 100%;

    }
    
}
</style>
