/*
 * @Author: WYY 
 * @Description: 
 * @ModuleName:   时间结束
 * @Date: 2019-01-11 17:27:20 
 * @Last Modified by: WYY
 * @Last Modified time: 2019-01-14 11:14:36
 */
<template>
    <el-dialog :visible="show_" :before-close='Close' title='Confirm' custom-class='TanChuang'>
        <div class="T_body">
            <p> Sorry,this time run out </p>
        </div>
        <div class="T_footer">
            <button class="confirm" @click="Close">Yes</button>
            <!-- <button class="cancel">取消</button> -->
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props:['show'],
        data(){
            return {
                show_:this.show,
            }
        },
        created() {

        },
        watch: {
            show:function(v){
                this.show_ = v;
            }
        },
        methods: {
            Close(){
                this.$emit('Close');
            },
        },
        computed: {
            
        },
    }
</script>

<style scoped>

</style>