import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

// 解决路由重复单击错误
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            name: 'start',
            redirect:'./home',
        },
        {
            path: '/home',
            component: Home,
            children:[
                {
                    path: '/',
                    name:'begin',
                    component: resolve =>require(["./views/begin.vue"], resolve)
                },
                {   // 描述 1
                    path: 'describe',
                    name:'describe',
                    component: resolve =>require(["./views/describe/describe.vue"], resolve)
                },
                {   // 模版页面
                    path: 'Template',
                    name:'Template',
                    component: resolve =>require(["./views/question/moban.vue"], resolve)
                },
                {   // 判断题  1
                    path: 'judgment',
                    name:'judgment',
                    component: resolve =>require(["./views/question/judgment.vue"], resolve)
                },
                {
                    // 人机面试（OPI） 1
                    path: 'Opi',
                    name:'Opi',
                    component: resolve =>require(["./views/question/Opi.vue"], resolve)
                },
                {
                    // 人机面试（OPI） 1
                    path: 'OpiNew',
                    name:'OpiNew',
                    component: resolve =>require(["./views/question/OpiNew.vue"], resolve)
                },
                {
                    // 简要回答 1
                    path: 'BriefAnswer',
                    name:'BriefAnswer',
                    component: resolve =>require(["./views/question/BriefAnswer.vue"], resolve)
                },
                {
                    // 飞行模拟
                    path: 'FlightSimulation',
                    name:'FlightSimulation',
                    component: resolve =>require(["./views/question/FlightSimulation.vue"], resolve)
                },
                {
                    path: 'ReadWord',
                    name:'ReadWord',
                    component: resolve =>require(["./views/question/ReadWord.vue"], resolve)
                },
                {
                    path: 'ReadWord1',
                    name:'ReadWord1',
                    component: resolve =>require(["./views/question/ReadWord1.vue"], resolve)
                },
                {
                    path: 'ReadWord2',
                    name:'ReadWord2',
                    component: resolve =>require(["./views/question/ReadWord2.vue"], resolve)
                },
                {
                    // 故事复述  1
                    path: 'RepeatStory',
                    name:'RepeatStory',
                    component: resolve =>require(["./views/question/RepeatStory.vue"], resolve)
                },
                {
                    // 发音练习
                    path: 'Pronunciation',
                    name:'Pronunciation',
                    component: resolve =>require(["./views/question/pronunciation.vue"], resolve)
                },
                {
                    // 模拟练习 1
                    path: 'SimulationExercise',
                    name:'SimulationExercise',
                    component: resolve =>require(["./views/question/SimulationExercise.vue"], resolve)
                },
                {
                    // 单项选择   短文 1
                    path: 'short',
                    name:'short',
                    component: resolve =>require(["./views/question/short.vue"], resolve)
                },
                {
                    // 多问选择   长文 1
                    path: 'long',
                    name:'long',
                    component: resolve =>require(["./views/question/long.vue"], resolve)
                },
                {
                    // 单项选择   短文 简
                    path: 'SimpleShort',
                    name:'SimpleShort',
                    component: resolve =>require(["./views/question/SimpleShort.vue"], resolve)
                },
                {
                    // 短文复述
                    path: 'ShortRetelling',
                    name:'ShortRetelling',
                    component: resolve =>require(["./views/question/ShortRetelling.vue"], resolve)
                },
                {
                    // 成绩报告
                    path: 'table',
                    name:'table',
                    component: resolve =>require(["./views/Table.vue"], resolve)
                },
                // 新增题型
                {
                    // 案例解析
                    path: 'CaseAnalysis',
                    name:'CaseAnalysis',
                    component: resolve =>require(["./views/question/CaseAnalysis.vue"], resolve)
                },
                {
                    // 单选（图）
                    path: 'SelectOnePicture',
                    name:'SelectOnePicture',
                    component: resolve =>require(["./views/question/SelectOnePicture.vue"], resolve)
                },
                {
                    // 词汇解析
                    path: 'LexicalDiscrimination',
                    name:'LexicalDiscrimination',
                    component: resolve =>require(["./views/question/LexicalDiscrimination.vue"], resolve)
                },
                {
                    // 用语辨析
                    path: 'LanguageDiscrimination',
                    name:'LanguageDiscrimination',
                    component: resolve =>require(["./views/question/LanguageDiscrimination.vue"], resolve)
                }
            ],
        }
    ]
})
