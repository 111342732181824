<template>
    <div>
        <div class='Popup' v-show="tshow">
            <div class="P_title">
                Directions
                <i class="el-icon-close right" @click="close()"></i>
            </div>
            <div class="P_content">
                <div class="ucon_title">
                    <span> Directions </span>
                </div>
                <div class="ucon_content" v-html="describe">
                </div>
            </div>
        </div>
        <div  v-show="tshow" class="cover"></div>
    </div>
    
</template>

<script>
    export default {
        props:['show','describe'],
        data(){
            return{
                tshow:this.show,
            }
        },
        watch:{
            show:function(v){
                this.tshow = v;
            },
        },
        methods:{
            close(){
                this.$emit('update:show',false);
            }
        },
    }
</script>

<style scoped>

</style>