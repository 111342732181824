/*
 * @Author: WYY 
 * @Description: 设备检测
 * @ModuleName: 设备检测
 * @Date: 2018-09-30 11:49:55 
 * @Last Modified by: qianjun
 * @Last Modified time: 2020-09-29 14:37:55
 */
<template>
    <div :class="['Check_box',Testing?'Check_t':'']" v-if='CheckShow'>
        <!-- <div class='Box_one'>
            <div :class="['bbox',show==0?'active':'']" @click="Change(0)">
                <img src="../assets/voice.png" alt="" srcset="">
                <span>扬声器检测</span>
            </div>
            <div :class="['bbox',show==1?'active':'']" @click="Change(1)">
                <img src="../assets/mic.png" alt="" srcset="">
                <span>麦克风检测</span>
            </div>
        </div>  -->
        <div class='Box_two'>
            <div class='Close' v-if='Testing' @click='Complate(1)'> <img src="../assets/Close.png" alt="" srcset=""> </div>
            <div class="Yang" v-if="show==0">
                <div class="t1">
                    <span class="T_label">选择耳机</span>
                    <el-select v-model="Headset" placeholder="请选择" @change="AudioSpeaker">
                        <el-option
                            v-for="(item,index) in Hlist"
                            :key="index"
                            :label="item.label"
                            :value="item.deviceId">
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="Headset" placeholder="暂无可使用设备" :disabled="true"></el-input> -->
                </div>

                <button class="t2" @click="AudioPlay()"> <img src="../assets/celaba.png" alt=""> <span>点击开始测试</span> </button>
                <p class="t3">
                    能听到音乐吗?
                </p>
                <p class="t4">
                    如果您无法听见声音，可能会是以下原因导致的，请逐一排查：
                </p>
                <p class="t5">
                    1、请确保耳机或是扬声器音量未调至静音状态；<br>
                    2、若安装的有杀毒软件，请在弹出的提示信息窗口中选择“允许”选项；<br>
                    3、请确保没有禁用电脑扬声器选项，若有多个扬声器插口，请更换插口后重新检测。<br>
                </p>
                <div class="t6">
                    <button class="no" @click='AudioTest(0)'> 听不到 </button>
                    <button class="yes" @click='AudioTest(1)'> 能听到 </button>
                </div>
            </div>
            <div class="Mai" v-else-if="show==1">
                <div class="t1">
                    <span class="T_label">选择麦克风</span>
                    <el-select v-model="Microphone" placeholder="请选择" @change="AudioSpeaker">
                        <el-option
                            v-for="(item,index) in Mlist"
                            :key="index"
                            :label="item.label"
                            :value="item.deviceId">
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="Microphone" placeholder="暂无可使用设备" :disabled="true"></el-input> -->
                </div>
                
                <div class='Volme'>
                    <span>
                        对着麦克风从1数到10，您能听到自己的声音并且看到绿色滚动条吗？
                    </span>
                    <ul>
                        <li v-for='li in 20' :key='li' :class="li<maxVal?'active':''"></li>
                    </ul>
                </div>
                <p class="t4">
                    如果您无法看到绿色进度条，可能会是以下原因导致的，请逐一排查：
                </p>
                <p class="t5">
                    1、请确保话筒正确接入电脑，且没有禁用电脑的话筒选项； <br>
                    2、若安装的杀毒软件，请在弹出的提示窗口中选择“允许”选项； <br>
                    3、若有多个话筒插口，请更换插口后重新检测。
                </p>
                <div class="t6">
                    <button class="no" @click='MicroTest(0)'> 看不到波动 </button>
                    <button class="yes" @click='MicroTest(1)'> 能看到波动 </button>
                </div>
            </div>
            <div class='Result' v-else>
                <div class='t1'>
                    检测报告
                </div>
                <div class='t2'>
                    <span> 检查项目 </span>
                    <span> 检查结果 </span>
                    <span> 检查详情 </span>
                </div>
                <div class='t3'>
                    <div v-for='(item,index) in Rlist' :key='index'>
                        <span> {{item.project}} </span>
                        <span :class='item.type==0?"error":""'> {{item.result}} </span>
                        <span> {{item.details}} </span>
                    </div>
                </div>

                <div class="t6">
                    <button class="no" @click="Complate(0)"> 重新检测 </button>
                    <button class="yes" @click="Complate(1)" > 检测完成 </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        data(){
            return{
                show:0,
                Headset:'',
                Hlist:[],//输出设备
                TestAudio:null,

                Microphone:'',
                Mlist:[],//输入设备
                maxVal:0,
                audiosrc:process.env.BASE_URL + 'horse.ogg',
                Rlist:[],

                checkInfo:{
                    audioInput:false,
                    audioOutput:false,
                },
            }
        },
        computed:{
            Testing:function(){
                return this.$store.state.common.Testing;
            },
            CheckShow:function(){
                return this.$store.state.common.CheckShow;
            },
        },
        watch:{
            CheckShow:function(v){
                if(v){
                    this.Hlist = [];
                    this.Mlist = [];
                    this.Rlist = [];
                    this.Headset = '';
                    this.Microphone = '';
                    this.show = 0;
                    this.Play();
                }
            },
            show:function(v){
                if(v==1){ // 麦克风检测
                    this.AudioInput();
                    console.log('检测耳机');
                }else{
                    this.$Recorder.stopRecord()
                    console.log('麦克风');
                }
            }
        },
        async created() {
            await this.Play();
        },
        methods:{
            async Play(){
                this.TestAudio = document.createElement('audio');
                this.TestAudio.src = process.env.BASE_URL + 'horse.ogg';
                // 初始化
                this.$Recorder.init()
                this.$Recorder.on('ready', () => {
                    this.Mlist = this.$Recorder.audioInputList;
                    this.Hlist = this.$Recorder.audioOutputList;
                    if(this.Hlist.length>0){
                        this.Headset = this.Hlist[this.Hlist.length - 1].label;
                        this.checkInfo.audioOutput = true;
                    }else{
                        console.log(this.Hlist);
                        this.$message.warning('无音频输出设备!');
                        this.checkInfo.audioOutput = false;
                    }
                    if(this.Mlist.length>0){
                        this.Microphone = this.Mlist[this.Mlist.length - 1].label;
                        this.checkInfo.audioInput = true;
                    }else{
                        this.$message.warning('无音频输入设备!');
                        this.checkInfo.audioInput = false;
                    }
                });
                sessionStorage.setItem("checkInfo",JSON.stringify(this.checkInfo))
                this.$Recorder.on('devicechange', () => {
                    console.log('设备信息发生了变化');
                });
                // 监听声音发生变化
                this.$Recorder.on('audioprocess', data => {
                    let all = 0
                    data.map(el=>{
                        all = all + Number(el) 
                    })
                    this.maxVal = all/10 - 127
                });
            },
            AudioPlay(){
                this.TestAudio.play();
            },
            async AudioSpeaker(item){
                this.TestAudio.pause();
                await this.TestAudio.setSinkId(item);
                this.TestAudio.play();
            },
            // 开始录音
            AudioInput() {
                console.log("开始录音");
                if (!this.$Recorder || !this.$Recorder.supported) {
                    alert('硬件不支持或当前电脑已禁用麦克风！');
                    return;
                }
                console.log(this.$Recorder.recording);
                if (this.$Recorder.recording) return;
                this.$Recorder.startRecord();
            },
            // AudioInput(){
                // Recorder.record({
                //     start: function () {
                //         console.log('开始录音!');
                //     },
                //     progress:(m,z)=>{
                //         this.maxVal = z/2;
                //     },
                // });
            // },
            Change(v){
                if(this.Testing){
                    this.show = v;
                }
            },
            AudioTest(v){
                let item = {
                    project:'扬声器检测',
                    result:'',
                    details:'',
                    type:v,
                }
                if(v==0){
                    item.result = '异常';
                    item.details = '未检测到扬声器设备';
                }
                if(v==1){
                    item.result = '正常';
                    item.details = '正常播放';
                }
                this.Rlist.push(item);

                this.show = 1;
            },
            MicroTest(v){
                let item = {
                    project:'麦克风检测',
                    result:'',
                    details:'',
                    type:v,
                }
                if(v==0){
                    item.result = '异常';
                    item.details = '未检测到麦克风设备';
                }
                if(v==1){
                    item.result = '正常';
                    item.details = '正常录音';
                }
                this.Rlist.push(item);

                this.show = null;
            },
            async Complate(v){
                await this.Play();
                // let flash = sessionStorage.getItem('HasFlash');
                // if(flash!='true'){
                //     // this.$message.warning('您的未安装Flash插件,请安装Flash插件再开始学习!');
                //     // return false;
                // }
                if(!this.checkInfo.audioInput){
                    this.$message.warning('无音频输入设备,请连接设备!');
                    // return false;
                }
                if(!this.checkInfo.audioOutput){
                    this.$message.warning('无音频输出设备,请连接设备!');
                    // return false;
                }
                if(v==0){
                    this.show = 0;   
                    this.Rlist = [];
                }
                if(v==1){
                    // let flash = document.querySelector('#recorderFlashContainer');
                    // if(!!flash){
                    //     flash.style.left = '-1000px';
                    // }
                    sessionStorage.setItem('checkcomplete','complete');
                    // 完成设备检测，回到home页面不再弹出
                    this.$store.commit('setitem',{'name':'CheckShow','item':false});
                    let begin = sessionStorage.getItem('begin');
                    let usertype = sessionStorage.getItem('usertype');
                    if(begin!='true'&&usertype!=0&&usertype!=1&&window.ContinueLearningCan=='true'){
                        this.StartStudy();
                    } else {
                        // this.$store.commit('setitem',{'name':'RemainingTime','item': 86400*365*10000});
                        // this.$store.commit('setContinueStudy') // 2020-09-29
                    }
                        this.$store.commit('setitem',{'name':'RemainingTime','item': 86400*365*10000});
                        this.$store.commit('setContinueStudy') // 2020-09-29
                }
            },
        },
        beforeDestroy() {
            // Recorder.stop();
            // Recorder.clear();
        },
    }
</script>

<style scoped>

</style>
