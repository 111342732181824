<template>
  <div class="VideoPlayer" >
    <!-- 这里设置了ref属性后，在vue组件中，就可以用this.$refs.audio来访问该dom元素 -->
    <video ref="video" style="width:100%;height:100%;"  :src="url" controls :disablePictureInPicture="true" controlsList="nodownload"></video>
  </div>

</template> 

<script>
 
  export default {
    props: {
      config:{
        type:Object,
        default:()=>{
          return{
            autoplay:true,
            theSpeeds:[1,2,3,4],
              
             // url:'http://192.168.1.214:7010/course/mp3/8593d765-d4d3-440d-b244-bcfcdc2117f1.mp3',
            // url:'http://resource.nwatmb.cn/7868DC019B5940A1B9B550E72D41BBF7.mp4',
          }
        }
      },
      theUrl: {
        type: String,
        default: ''
      },
      
    },
    name: 'VueVideo',
    data() {
      return {
        
        url:this.$store.state.common.AudioUrl+this.theUrl,
        // url:"http://192.168.1.214:7010/course/mp3/8593d765-d4d3-440d-b244-bcfcdc2117f1.mp3",
        video:{
          playing:false,//播放状态

        }

      }
    },
    watch:{
      theUrl:{
        handler:function(v){
          if(v){
            this.url = this.$store.state.common.AudioUrl + v
          }
        },
        immediate:true
      },
      outStudyShow:{
        handler:function(v){
          if(v){
            this.exitfullscreen();
          }
        },
        immediate:true
      }
    },
    computed:{
      outStudyShow:function(){
          return this.$store.state.common.outStudyShow;
      }
    },
    mounted(){
      this.videoEnded()
    },
    methods: {
      //待机时退出全屏状态
       exitfullscreen() {
          try{
            this.pausePlay();
            let doc = document;
            if(
              doc.fullscreenElement ||
              doc.webkitFullscreenElement ||
              doc.mozFullScreenElement ||
              doc.msExitFullscreenElement
            ){
              if (doc.exitFullscreen) {
                doc.exitFullscreen();
              } else if (doc.mozCancelFullScreen) {
                doc.mozCancelFullScreen();
              } else if (doc.webkitCancelFullScreen) {
                doc.webkitCancelFullScreen();
              }
            }
          }catch(e){
            console.log('noFullscreen');
          }
      },
      startPlayOrPause() {
        return this.video.playing ? this.pausePlay() : this.startPlay()
      },
      // 开始播放
      startPlay() {
        console.log("播放音频路径：" + this.url);
        // 先暂停所有视频播放
        let videos = document.getElementsByTagName('video');
        [...videos].forEach((item) => {
            item.pause()
        })
        // 先暂停所有音频播放
        let audios = document.getElementsByTagName('audio');
        [...audios].forEach((item) => {
            item.pause()
        })
        
        setTimeout(()=>{
          this.$refs.video && this.$refs.video.play()
        },100)
        this.video.playing = true
      },
      // 获取播放状态
      getPlaystate() {
        return this.video.playing 
      },
      // 获取当前音频长度
      getPlayLength() {
        return this.video.playing 
      },
      // 暂停
      pausePlay() {
        this.$refs.video.pause()
        this.video.playing = false
      },
      // 播放完成
      videoEnded(){
        this.$refs.video.addEventListener('ended',  () => {  
            this.$emit("videoEndedOver",true)
        }, false);
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .VideoPlayer{
    width: 100%;
    position: relative;
  }
</style>