/*
 * @Author: WYY 
 * @Description: 
 * @ModuleName:   测试报告
 * @Date: 2019-01-11 17:27:20 
 * @Last Modified by: WYY
 * @Last Modified time: 2019-01-14 11:55:25
 */
<template>
    <el-dialog :visible="show_" :before-close='Close' title='Congratulations' custom-class='TanChuang'>
        <div class="T_body" style="padding:60px 70px;">
            <p>The test has been completed ! Could you view performance report?</p>
        </div>
        <div class="T_footer">
            <button class="confirm" @click="Confirm">Yes</button>
            <button class="cancel" @click='Close'>No</button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props:['show'],
        data(){
            return {
                show_:this.show,
            }
        },
        created() {

        },
        watch: {
            show:function(v){
                this.show_ = v;
            }
        },
        methods: {
            Close(){
                let data = this.$store.state.common.reportData;
                if(data.over == 'over'){
                    this.$notify({
                        title: 'Congratulations',
                        message: 'Complete all chapters !',
                        duration: 5000
                    });
                }else{
                    this.GoNext(data.c);      
                }
                this.$store.commit('setitem',{name:'quitCourseShow',item:false});
            },
            Confirm(){
                let data = this.$store.state.common.reportData;
                if(data.over == 'over'){
                    sessionStorage.setItem('temporary','over');
                    this.$router.push('/home/table');
                }else{
                    let tar = JSON.stringify(data.c);
                    sessionStorage.setItem('temporary',tar);
                    this.$router.push('/home/table');
                }
            }
        },
        computed: {
            
        },
    }
</script>

<style scoped>

</style>