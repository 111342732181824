<style lang="less" scoped>
    .right{
        display: flex;
        display: -webkit-flex;
        flex-direction:row;
        align-items: center;
    }
    .ShuiYinRight1{
        cursor: default !important;
        color: #000;
    }
    .outStudy_out{
        cursor: pointer !important;
    }
</style>

<template>
    <div class="right">
        <!-- <div class="outStudy" v-if="Pattern=='测试模式'"  @click="toggleClick">
            <img src="../assets/pause.png" v-if="testPause" alt="" >
            <img src="../assets/play.png" v-if="!testPause" alt="" >
        </div> -->
              <div class="ShuiYinRight1">{{enterpriseName}}  </div>
        <div class="outStudy_out"  @click='SignOut()'    style="margin-left:15px;">
            <img src="../assets/outStudy.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
            }
        },
        created() {
            console.log(this.Pattern,'222',this.Pattern=='测试模式')
        },
        computed: {
            testPause:function(){
                return this.$store.state.common.testPause;
            },
            enterpriseName() {
                return this.$store.state.common.queryConfig.name
            }
        },
        methods: {
            toggleClick(){
                this.$store.commit('setitem',{name:'testPause',item:!this.testPause});
            },
        },
    }
</script>

<style scoped>

</style>