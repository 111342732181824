{
    let c = document.documentElement.clientWidth;
    // if (c > 1920) {
    //     c = 1920;
    // }
    document.documentElement.style.fontSize = c / 19.2 + "px";
}


window.onresize = function(){
    let c = document.documentElement.clientWidth;
    // if (c > 1920) {
    //     c = 1920;
    // }
    document.documentElement.style.fontSize = c / 19.2 + "px";
}




window.Panduanxx = function(param) {
    let dd = Object.prototype.toString.call(param);
    switch (dd) {
        case '[object String]':
            return 'string'
            break;
        case '[object Number]':
            return 'number'
            break;
        case '[object Boolean]':
            return 'boolean'
            break;
        case '[object Array]':
            return 'array'
            break;
        case '[object Object]':
            return 'json'
            break;
        case '[object Function]':
            return 'function'
            break;
        case '[object Null]':
            return 'null'
            break;
        case '[object Undefined]':
            return 'undefined'
            break;
        case '[object Date]':
            return 'date'
            break;
        case '[object RegExp]':
            return 'RegExp'
            break;
        default:
            break;
    }
}
window.Ts = [];
window.Test = function(list){
    if(list.length>0){
        for (let i = 0; i < list.length; i++) {
            const el = list[i];
            if(!!el.childList&&el.childList.length>0){
                let c = el.catalog;
                window.Ts.push(c);
                window.Test(el.childList);
            }else{
                let item = list[i].catalog;
                // item.childList = null;
                window.Ts.push(item);
            }
        }
    }
}


window.LayerList = [];
window.Menulist = function(shan,item,shangcen,cen){
    let MaxPattern = sessionStorage.getItem('MaxPattern');
    let studylog = JSON.parse(sessionStorage.getItem('studylog'));
    let ceng = cen || 1;
    let list = item.map((el,index)=>{
        el.Tshow = false;
        if(MaxPattern=='闯关模式') el.lock = true;
        else el.lock = false;

        if(!!!el.allSectionModel){
            el.allSectionModel = '1';
        }
        if(shan){
            if(el.allSectionModel == '3'){
                el.childList = [];
            }
            if(!!el.tbCourse&&!!el.tbCourse.catalogPatternName&&el.tbCourse.catalogPatternName=='测试模式'){
                el.childList = [];
            }
        }
        if(!!shangcen){
            el.ceng = shangcen + ',' + ceng + '.' + index;
        }else{
            el.ceng =  ceng + '.' + index;
        }
        let begin = sessionStorage.getItem('begin');
        if(el.id==window.ContinueLearning&&begin!='true'){
            if(!!el.tbCourse&&el.tbCourse.patternName!='测试模式'){
                window.ContinueLearningCan = 'true';
            }
            if(!!el.allSectionModel&&el.allSectionModel!=3){
                window.ContinueLearningCan = 'true';
            }
            sessionStorage.setItem('targetceng',el.ceng);
        }
        if(shan){
            window.LayerList.push(el.ceng);
            if(studylog.indexOf(el.id)>-1){
                el.lock = false;
            }
        }
        if(!!el.childList&&el.childList.length>0){
            let c = ceng + 1;
            el.childList = window.Menulist(shan,el.childList,el.ceng,c);
        }
        return el;
    })
    return list;
}


window.MenuLayer = function(item){
    let d = {
        alength:item.length,
        childList:null,
    }
    let c = [];
    item.forEach(el => {
        let a = {};
        if(!!el.childList&&el.childList.length>0){
            a = window.MenuLayer(el.childList);
        }
        c.push(a);
    });
    d.childList = c;
    return d;
}



function strSimilarity2Number(s, t) {
    var n = s.length, m = t.length, d = [];
    var i, j, s_i, t_j, cost;
    if (n == 0) return m;
    if (m == 0) return n;
    for (i = 0; i <= n; i++) {
        d[i] = [];
        d[i][0] = i;
    }
    for (j = 0; j <= m; j++) {
        d[0][j] = j;
    }
    for (i = 1; i <= n; i++) {
        s_i = s.charAt(i - 1);
        for (j = 1; j <= m; j++) {
            t_j = t.charAt(j - 1);
            if (s_i == t_j) {
                cost = 0;
            } else {
                cost = 1;
            }
            d[i][j] = Minimum(d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost);
        }
    }
    return d[n][m];
}

function Minimum(a, b, c) {
    return a < b ? (a < c ? a : c) : (b < c ? b : c);
}
window.strSimilarity2Percent=function(s, t){
    var l = s.length > t.length ? s.length : t.length;
    var d = strSimilarity2Number(s, t);
    return (1 - d / l).toFixed(4);
}


window.StringAlignment = function(alist,amsg){
    let list = window.Copy(alist);
    let msg = window.Copy(amsg);
    let plist = [];
    list.map(el=>{
        el.catalog = el.catalog.toLowerCase();
        msg = msg.toLowerCase();
        let lv = el.catalog.indexOf(msg);
        let item = {
            lv:lv,
            len:el.catalog.length,
            el:el,
        }

        if(lv>-1){
            plist.push(item);
        }
        if(!!el.childList&&el.childList.length>0){
            let back = window.StringAlignment(el.childList,msg);
            plist = plist.concat(back);
        }
    })
    
    
    return plist;
}



window.Copy = function(item) {
    let leixing = window.Panduanxx(item);
    let Tidai = null;
    if (leixing == 'json') {
        let str = JSON.stringify(item);
        str = JSON.parse(str);
        Tidai = str;
    } else {
        let jsona = {};
        jsona.item = item;
        let str = JSON.stringify(jsona);
        str = JSON.parse(str);
        Tidai = str.item;
    }
    return Tidai;
}


window.ComputedTime = function(time){
    // console.log(time);
    
    let t = Math.floor(time / 60);
    let m = Math.floor(time%60);
    let c = t<10?('0'+t):(t) + ''  ;
    c += ":";
    c += m<10?('0'+m):m;
    return c;
}



window.PlayerState = function(c){

    return c;
}

window.formatDuring = function(mss) {
    mss = parseInt(mss)
    mss = mss*1000;
    var hours = parseInt((mss / (1000 * 60 * 60)));
    var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = (mss % (1000 * 60)) / 1000;
    if(hours<10){
        hours = '0' + hours;
    }
    if(minutes<10){
        minutes = '0' + minutes;
    }
    if(seconds<10){
        seconds = '0' + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
}

window.formatDateTime = function (date) {  
    var y = date.getFullYear();  
    var m = date.getMonth() + 1;  
    m = m < 10 ? ('0' + m) : m;  
    var d = date.getDate();  
    d = d < 10 ? ('0' + d) : d;  
    var h = date.getHours();  
    h=h < 10 ? ('0' + h) : h;  
    var minute = date.getMinutes();  
    minute = minute < 10 ? ('0' + minute) : minute;  
    var second=date.getSeconds();  
    second=second < 10 ? ('0' + second) : second;  
    return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;  
};  




