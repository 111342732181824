<template>
    <div class="T_tree">
        <div v-for="item in Menulist"  :class="['el_node',outerId==item.id?'ischecked':'',((item.isEnd=='1'||item.allSectionModel=='2')&&checkid==item.id)?'active':'']" :key="item.id" >
            <div :class="['el_node_n']" @click="Verification(item)" :style="'margin-left:'+ 18 * (item.layer-1) +'px'">
                <el-tooltip effect="light" placement="right" :content="item.catalog" :hide-after=3000>
                    <p class="el_node_n_content">{{item.catalog}}</p>
                </el-tooltip>
                <span :class='["Yuan","color"+item.layer]'></span>
                <span :class="['el_node_icon',item.pageSize?'rotate':'']" v-if="item.isEnd=='0'&&item.allSectionModel==1&&item.childList.length>0" @click.stop="UpDown(item)"></span>
                <span v-if="(item.isEnd=='1'||item.allSectionModel=='2')&&checkid==item.id"  :class='["Xian","Xian"+item.layer]'></span>
            </div>
            <el-collapse-transition>
                <tree v-show="item.isEnd=='0'&& item.pageSize" :Menulist.sync='item.childList' :Tid.sync='Tid' :checkid='checkid' :layerlist='layerlist'></tree>
            </el-collapse-transition>
        </div>
    </div>
</template>

<script>
    export default {
        name:'tree',
        props:{
            Menulist:{
                type:Array,
            },
            Tid:{
                type:String,
                default:'',
            },
            checkid:{
                type:String,
                default:'',
            },
            outerId:{
                type:String,
                default:'',
            },
            layerlist:{
                type:Array,
            },
        },
        data(){
            return {
                node:'tree',
                show:false,
            }
        },
        
        computed:{
            MaxPattern:function(){
                return this.$store.state.common.MaxPattern;
            },
        },
        watch:{
            Menulist:function(v,oldv){
                if(v!=oldv){
                    this.$emit('update:menulist',v);
                }
            },
            layerlist:function(v){
                let list = window.Copy(this.Menulist);
                if(!!list&&list.length>0){
                    for (let index = 0; index < list.length; index++) {
                        if(this.checkid == list[index].id){
                            list[index].lock = false;
                        }
                        if(v.indexOf(list[index].ceng)>-1){
                            list[index].Tshow = true;
                        }
                    }
                    if(list!=this.Menulist){
                        this.$emit('update:menulist',list);
                    }
                }
            },
        },
        methods:{
            UpDown(item){
                item.pageSize = !item.pageSize;
            },
            Verification(item){
                if(this.Pattern == '测试模式'){
                    this.$confirm('正在测试中,确认离开?', '提示', {
                        confirmButtonText: '离开',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.Goto(item);
                    }).catch(() => {
                        console.log('取消');
                    });
                }else{
                    this.Goto(item);
                }
            },
            Goto(item){
                let list = this.menulist;
                let layer = item.ceng.split(',');
                layer = layer.map((el)=>{
                    el = el.split('.');
                    return el;
                })
                if(item.allSectionModel == 3){ // 测试模式
                    let starttime = new Date();
                    starttime = window.formatDateTime(starttime);
                    sessionStorage.setItem('starttime',starttime);
                }

                sessionStorage.setItem('checkid',item.id);
                sessionStorage.setItem('targetceng',item.ceng);

                this.$store.commit('setitem',{'name':'checkid','item':item.id})
                this.$store.commit('setitem',{'name':'outerId','item':list[layer[0][1]].id});
                this.$store.commit('setitem',{'name':'dataLayer','item':item.ceng})
                this.$router.push('/home/describe?pid=' + item.pid);
            },
        },
    }
</script>

<style scoped>

</style>
