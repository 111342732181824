<!--
 * @description  : 
 * @Author       : Junfeng Fu
 * @Date         : 2022-11-25 16:32:12
 * @LastEditors  : Junfeng Fu
 * @LastEditTime : 2023-03-07 17:09:33
-->
/*
 * @Author: WYY 
 * @Description: 
 * @ModuleName:   8分钟停止学习
 * @Date: 2019-01-11 17:27:20 
 * @Last Modified by: WYY
 * @Last Modified time: 2019-02-22 16:05:51
 */
<template>
    <el-dialog :visible="show_" :before-close='Close' :close-on-click-modal="false" title='Hint' custom-class='TanChuang'>
        <div class="T_body">
            <p style="word-break: break-word;"> You have not studied for a long time. The study has been suspended. </p>
        </div>
        <div class="T_footer">
            <button class="confirm" @click="Confirm">Continue</button>
            <button class="cancel" @click='Close'>Quit</button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props:['show'],
        data(){
            return {
                show_:this.show,
            }
        },
        created() {

        },
        watch: {
            show:function(v){
                // 刷新学习记录时间
                if(v){
                    // sessionStorage.setItem("chapterSuspendTime",Date.parse(new Date()))
                }else{
                    // sessionStorage.setItem("chapterSuspendTimeEnd",Date.parse(new Date()))
                }
                this.show_ = v;
            }
        },
        methods: {
            Close(){
                sessionStorage.setItem("chapterSuspendTimeEnd",Date.parse(new Date()))
                this.$store.commit('setitem',{'name':'outStudyShow','item':false});
                this.OutSide(true);
                // if(this.$ipcRenderer){
                //     sessionStorage.clear()
                //     localStorage.clear()
                //     this.$ipcRenderer.send("exit");
                // }
            },
            async Confirm(){
                const dateRes=await this.AjaxApi.getSevDate();
                sessionStorage.setItem("newChapterStartTime",Date.parse(dateRes.data))
                sessionStorage.setItem("chapterSuspendTimeEnd",Date.parse(new Date()))
                this.$store.commit('setContinueStudy');
            }
        },
        computed: {
        },
    }
</script>

<style scoped>

</style>