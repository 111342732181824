import axios from 'axios'
import Qs from 'qs'
import { Message } from 'element-ui'
import Url from '../util.js'

export const Axios = axios.create({
    // 总
    baseURL: Url.BaseUrl,
    timeout: 30000,
    // transformRequest: [function (data) {
    //     data = Qs.stringify(data);
    //     return data;
    // }],
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
})
//POST传参序列化(添加请求拦截器)
Axios.interceptors.request.use(config => {
    if(sessionStorage.getItem('token')){
        config.headers['Token_'] = sessionStorage.getItem('token') || '';
    }
    
    if (config.url.indexOf('getNoteByQuestionId') > -1||config.url.indexOf('addCourseAdvice') > -1||config.url.indexOf('updateCourseNote') > -1||config.url.indexOf('addAnswerRecord')>-1||config.url.indexOf('addTbStudyLog') > -1||config.url.indexOf('startStudy') > -1||config.url.indexOf('heartBeat') > -1||config.url.indexOf('quitLearning') > -1||config.url.indexOf('checkHeartBeat') > -1||config.url.indexOf('addVideo') > -1||config.url.indexOf('getNewVideo') > -1 || config.url.indexOf('getScore') > -1 || config.url.indexOf('getTotalScore') > -1 || config.url.indexOf('getAvgScore') > -1 || config.url.indexOf('getIcaoScore') > -1 || config.url.indexOf('endTest') > -1 || config.url.indexOf('changeStudyTime') > -1 || config.url.indexOf('testRequire')>-1) {
        config.baseURL = Url.ReUrl;
        config.url = config.url.replace(Url.BaseUrl, Url.ReUrl);
    }
    if (config.url.includes('postFile')) {
        config.headers = Object.assign(config.headers,{'content-Type': 'multipart/form-data',})
    }
    // 在发送请求之前
    return config
}, error => {
    alert("参数错误", 'fail')
    return Promise.reject(error)
})

//返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(function(response){
    const res = response.data;
    if(res.code === '-101' || res.code === '-200' || res.code === '100' || res.code === '-900' || res.code === '-102'){
        Message({
            message: res.message,
            duration: 1 * 1000,
            showClose: true,
            onClose: function () { // location.reload()
            }
        })
        return Promise.reject(res.message)
    }else if(res.code === '-100'){  // 权限过期
        Message({
            message: res.message,
            duration: 1 * 1000,
            showClose: true,
            onClose: function () { // location.reload()
            }
        })
        return Promise.reject(res.message)
    }else if(res.code === '-103'  || res.code === '-105'){
        Message({
            message: res.message,
            duration: 1 * 1000,
            showClose: true,
            onClose: function () { // location.reload()
            }
        })
        return Promise.reject(res.message)
    }else if(res.code === '-104' || res.code === '-1120'){ // 账号其他地方登陆 或  不能学习 
        Message({
            message: res.message,
            duration: 2 * 1000,
            showClose: true,
            onClose: function () { // location.reload()
                window.close();
            }
        })
        // return Promise.reject(res.message)
    }else{
        //对响应数据反馈
        return response.data;
    }
}, function(error){
    // 由于上传响应比较慢，为了提升用户体验，不做提示
    // Message({
    //     message: error.message,
    //     type: 'error',
    //     duration: 5 * 1000
    // })
    // 返回 response 里的错误信息
    return Promise.reject(error.response.data)
})


// 上传
const Test = axios.create({
    // 总
    baseURL: 'https://www.baidu.com/',
    timeout: 15000,
    headers: {
        "Content-Type": "multipart/form-data ",
    },
})
//POST传参序列化(添加请求拦截器)
Test.interceptors.request.use(config => {
    // 在发送请求之前
    return config
}, error => {
    alert("参数错误", 'fail')
    return Promise.reject(error)
})

//返回状态判断(添加响应拦截器)
Test.interceptors.response.use(function(res){    
    //对响应数据反馈
    return res.status;
}, function(error){
    // 返回 response 里的错误信息
    return Promise.reject(error.response.data)
})

export default {
    install(Vue) {
        Object.defineProperty(Vue.prototype, '$ajax', {
            value: Axios
        })
        Object.defineProperty(Vue.prototype, '$Test', {
            value: Test
        })
    },
}