<template>
    <div class="recorder">
        <!-- <el-button @click="handleBtnClick">开始录音</el-button>
        <el-button @click="stopVoice">停止录音</el-button> -->
    </div>
</template>

<script>
import debounce from '@/utils/debounce'
import RecorderViews from '@/utils/recorder.js'
import Recorderx, { ENCODE_TYPE } from "recorderx"
import Recorder from '@/libs/recorder/myRecorder.js';
const rc = new Recorderx()
    export default {
        data(){
            return{
                currentTime:"",
                timeCount1:"",
                timeCount2:"",
                recordObj:"",
                record:"",
                maskingShow:"",
                recording:"",
                resData:{}
            }
        },
        methods:{
            translationStart(){
                console.log('开始录音');
                let that = this
                that.rc = new Recorderx()
                that.$nextTick(()=>{
                    that.rc.start()
                    .then(() => {
                        console.log("start recording");
                    }).catch(error => {
                        console.log("Recording failed.", error);
                    });
                })
            },

            //录音结束
            stopVoice(){
                this.rc.pause()
                var wav = this.rc.getRecord({
                    encodeTo: ENCODE_TYPE.WAV,
                });
                this.wavFile = new File([wav], 'SoundRecording.wav', { type: 'audio/x-wav' })
                this.uploadStationInfo()
                // this.uplode(wav)
            },

            //生成随机数
            getMath() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            },
            //重置数据
            baseSet () {
                this.currentTime = 0
                this.timeCount1 = '00:00:00.000'
                this.timeCount2 = '00:00:00.000'
            },
            //检查麦克风
            handleBtnClick () {
                console.log('检查麦克风');
                // this.recordObj = new RecorderViews()
                // this.recordObj.recOpen()
                navigator.getUserMedia = navigator.getUserMedia ||
                    navigator.webkitGetUserMedia ||
                    navigator.mozGetUserMedia ||
                    navigator.msGetUserMedia

                if (navigator.getUserMedia) {
                    this.translationStart()
                } else {
                    console.log("获取麦克风失败");
                    // alert('获取麦克风失败')

                }
            },
            //录制语音
            Handlerecord () {
                this.baseSet()
                this.record = !this.record
                let that = this
                rc.clear()
                rc.start()
                    .then(() => {
                        this.recordObj.recStart()
                        console.log(this.recordObj, ' this.recordObj')
                        // this.timeCount1 = getTime(this.recordObj.duration,1)
                        // this.testTimer()
                        this.maskingShow = true
                        this.recording = true
                        console.log("start recording")
                    })
                    .catch(error => {
                        that.record = false
                        console.log("Recording failed.", error)
                    })
            },
            //停止录音
            stopVoice1 () {
                this.recordObj.recStop()
                this.ms = this.recordObj.duration
                this.maskingShow = false
                this.record = !this.record
                // this.stop()
                rc.pause()
                this.getVoice()
            },

            //获取录音文件
            getVoice: debounce(async function () {
                this.loading = true
                var wav = rc.getRecord({
                    encodeTo: ENCODE_TYPE.WAV,
                    compressible: true
                })
                // if (wav.size < 95154) {
                //     this.loading = false
                //     return this.$message.error('录制时间太短，请重新录制')
                // }
                this.wavFile = new File([wav], 'SoundRecording.wav', { type: 'audio/x-wav' })
                this.uploadStationInfo()
            }),
            //上传文件到服务器
            async uploadStationInfo () {

                this.loading = true
                let formData = new FormData()
                formData.append("file", this.wavFile)
                this.resData =  await this.AjaxApi.upRecord(formData)
                this.$emit("upRcData",this.resData)
                // let { code, data, message } = await this.OnlineIdentification.upload(formData)
                // if (code == 200) {
                //     // await player.append(this.wavFile)
                //     formData.append("filePath", data)
                //     formData.append("fileDuration", (this.ms / 1000).toFixed(2))
                //     formData.append("phoneticType", '')
                //     formData.append("dataSourceType", 'realTimeVoice')
                //     this.send(formData)
                //     // this.loading = false
                // } else {
                //     // this.loading = false
                //     this.$message.error(message)

                // }
            },
            // 获取上传录音的res
            getResData(){
                return this.resData
            }
        }
    }
</script>

<style lang="less" scoped>

</style>