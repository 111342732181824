import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

// ElementUI 引入
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// ElementUI 引入

// 将挂载到prototype单独抽离成一个文件
import plugin from './utils/Bus';
Vue.use(plugin);



require("./js/login.js")
import axios from './axios.js'
Vue.use(axios)
import AjaxApi from './js/AjaxApi'
Vue.use(AjaxApi)

//引入recoder插件
import Recorder from './libs/recorder/myRecorder.js';
Vue.prototype.$Recorder = new Recorder()

//注册音频播放器
import AudioPlayer from "@/components/AudioPlayer"
Vue.component('AudioPlayer', AudioPlayer)
//注册视频播放器
import VideoPlayer from "@/components/VideoPlayer"
Vue.component('VideoPlayer', VideoPlayer)
//注册录音组件
import RecorderComponent from "@/components/RecorderComponent.vue"
Vue.component('Recorder', RecorderComponent)

require('./mixin.js')
// vue 混合

import popup from './views/describe/Popup.vue'
Vue.component('popup',popup)
import Time from './components/timeRecord.vue'
Vue.component('Time',Time)

if (window.require) {
    Vue.prototype.$ipcRenderer = window.require("electron").ipcRenderer
}


Vue.config.productionTip = false

new Vue({
    router:router,
    store,
    render: h => h(App)
}).$mount('#app')
