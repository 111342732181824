import Vue from 'vue'
import { log } from 'core-js';
import videoJs from 'video.js'

Vue.mixin({
    data(){
        return {
            ClickLimit:false,
            TiScore:0,
            ScoreShow:false,
            ComplateTest:0,
            reg:/<[^>]*>|<\/[^>]*>/gm,
            recordTime:0,
            // 二次播放  定时器
            twicePlayTimeOut:null,
            status:"ListeningText" , // 页面状态 听录音:PlayRecording  听题:ListeningText  录音:StartRecording
            targetId:""
        }
    },
    computed:{
        threeList:function(){
            return this.$store.state.common.threeList;
        },
        threeActive:function(){
            return this.$store.state.common.threeActive;
        },
        Username:function(){
            let name = sessionStorage.getItem('userName');
            return name;
        },
        menulist:function(){
            return this.$store.state.common.menulist;
        },
        Pattern:function(){
            return this.$store.state.common.Pattern;
        },
        RemainingTime:function(){
            return this.$store.state.common.RemaTime;
        },
        usertype:function(){
            return this.$store.state.common.usertype;
        },
        isJudgment:function(){
            // let networkConnection = sessionStorage.getItem('networkConnection');
            // if(networkConnection!='200'){// 不能访问  百度
            //     return '0';
            // }
            let c =  this.$store.state.common.isJudgment;
            let d = this.$store.state.common.RoleIsJudgment;
            console.log(c,d,'是否需要打分');


            return c == 1 && d == 1 ? 1 : 0
            // let c = '0';
            // let d = '1';
            // if(c == '0'){
            //     return '0';
            // }else if(c == '1'){
            //     if(d == '0'){
            //         return '0';
            //     }else if(d == '1'){
            //         return '1'
            //     }else{
            //         return '0';
            //     }
            // }else{
            //     return '0';
            // }

        },

    },
    watch:{

    },
    created(){
        sessionStorage.setItem('PlayTwice','0'); // 限制播放次数
    },
    mounted() {
        let d = document.querySelector('#recorderFlashContainer');
        if(!!d){
            d.style.left='-1000px';
        }

    },
    methods:{
        GetTargetItem(){
            let layer = sessionStorage.getItem('targetceng').split(',');
            layer = layer.map((el)=>{
                el = el.split('.');
                return el;
            })
            let menulist = JSON.parse(sessionStorage.getItem('menulist'));
            let str = 'menulist';
            let last = null;
            let list = 'menulist';
            let con  = '';
            let upstr = "menulist";
            layer.forEach((el,index)=>{
                if(index==0){
                    str += '['+ el[1] +']';
                    upstr += '['+ el[1] +']';
                }else if(index>0&&index<(layer.length-1)){
                    str += '.childList['+el[1]+']';
                    upstr += '.childList['+el[1]+']';
                }else{
                    last = el[1];
                    str += '.childList';
                }
                if(index==0){
                    list += '['+ el[1] +']';
                }else{
                    list += '.childList['+el[1]+']';
                }
                con += list+".catalog+'/'+";
            })
            con = con.substring(0,(con.length-1));

            let title = eval(con);
            let item = {
                last:last,
                data:eval(str),
                updata:eval(upstr),
                title:title.substring(0,(title.length-1)),
            };
            this.$store.commit('setitem',{name:'TargetItem','item':item});
            return item;
        },
        async GetQuestion(questionId,mainId){
            let url = '/question/getQuestionDetail?questionId=' + questionId + "&mainId=" + mainId;
            let url_;
            try{
                url_ = await this.$ajax.get(url);
            }catch(e){
                this.$message.error('获取数据失败!');
            }
            return url_;
        },
        async Tongyong(url) {
            // 通用  ajax 获取 数据 默认 Get
            // 数据格式  {'url':url,'type':'','txt':txt}
            let type = window.Panduanxx(url);
            if(type!='json'){
                url = {
                    'url':url,
                }
            }
            if (!url.type) {
                url.type = 'get';
            }
            if(!url.txt){
                url.txt = '获取信息失败!';
            }
            let c;
            if(url.type=='get'){
                try{
                    c = await this.$ajax.get(url.url);
                }catch(e){
                    this.$message.error(url.txt);
                    console.log(url.url);
                    console.log(e)
                    c = false;
                }
            }
            if(url.type=='post'){
                try{
                    c = await this.$ajax.post(url.url,url.str);
                }catch(e){
                    this.$message.error(url.txt);
                    console.log(url.url)
                    c = false;
                }
            }
            return c;
        },

        // 返回  c 子题  q 问题
        T_back(cIndex,cAll,qIndex,qAll){
            if(!!this.mshow){
                if(this.mshow.key!=0)this.mshow.key = 1;

            }
            this.$store.commit('setAdd','MenuSwitch');
            // 判断  先判断  子题
            if(cIndex == 0){
                if(qIndex == 0){
                    this.layerComputed(0);
                }else{
                    sessionStorage.setItem('PlayTwice','0'); // 播放次数 限制
                    this.prevQuestion();
                    this.CheckHeart();
                }
            }else{
                this.prevChildQuestion();
            }
        },
        // 下一步 c 子题  q 问题
        T_next(cIndex,cAll,qIndex,qAll){
            if(!!this.mshow){
                if(this.mshow.key!=0)this.mshow.key = 1;
            }
            this.$store.commit('setAdd','MenuSwitch');
            if(cIndex == (cAll - 1)){
                if(qIndex == (qAll - 1)){
                    this.layerComputed(1);
                }else{
                    sessionStorage.setItem('PlayTwice','0'); // 播放次数 限制
                    this.nextQuestion();
                    this.CheckHeart();
                }
            }else{
                this.nextChildQuestion();
            }
        },
        // 心跳检查
        async CheckHeart(){
            let type = sessionStorage.getItem('usertype');
            if(type == 1){
                return false;
            }
            let url_ = this.AjaxApi.CheckHeart();
            if(url_.code=='-110'){
                // this.SignOut();
                window.close();
            }
        },
        // layer computed  type 0 back 1 next
        layerComputed(type){
            let layer = sessionStorage.getItem('targetceng').split(',');
            layer = layer.map(el=>{
                el = el.split('.');
                return el;
            })
            let dataStructure = this.$store.state.common.dataStructure;
            let c = this.layerTest(layer,dataStructure,type,true);
            // 最后 末子节点  完成结束 提示
            if(c.length<=0&&type==1){
                let j = sessionStorage.getItem('isJudgment');
                if(this.Pattern=='测试模式'&&j=='1'){
                    let tar = JSON.stringify(c);
                    sessionStorage.setItem('temporary',tar);
                    this.$router.push('/home/table');
                }else{
                    this.$message('Congratulations Complete all chapters !');
                    // this.$notify({
                    //     title: 'Congratulations',
                    //     message: 'Complete all chapters !',
                    //     duration: 5000
                    // });
                }
                return;
            }
            if(c.length==1){
                let j = sessionStorage.getItem('isJudgment');
                if(j=='1'){
                    if(this.Pattern=='测试模式'){
                        let tar = JSON.stringify(c);
                        sessionStorage.setItem('temporary',tar);
                        this.$router.push('/home/table');
                    }else{
                        this.GoNext(c,'1');
                    }
                }else{
                    this.GoNext(c,'1');
                }
            }else{
                this.GoNext(c);
            }
        },
        GoNext(c,TCeng){
            let list = this.$store.state.common.menulist;
            let str = 'list';
            c.forEach((el,index)=>{
                if(index==0){
                    str += '['+ el[1] +']';
                }else{
                    str += '.childList['+el[1]+']';
                }
            })
            let tar = eval(str);
            let outer = list[c[0][1]];
            let studylog = JSON.parse(sessionStorage.getItem('studylog'));
            if(!!studylog&&studylog.length>0&&this.$store.state.common.MaxPattern == '闯关模式'){
                let pan = studylog.indexOf(tar.id);
                if(pan<0){
                    this.$message.warning('尚未解锁!');
                    return;
                }
            }
            if(studylog.indexOf(tar.id)<0){
                studylog.push(tar.id);
                sessionStorage.setItem('studylog',JSON.stringify(studylog));
            }
            if(tar.allSectionModel == 3 && TCeng == '1'){ // 测试模式 添加 开始测试时间
                let starttime = new Date();
                starttime = window.formatDateTime(starttime);
                sessionStorage.setItem('starttime',starttime);
            }
            sessionStorage.setItem('checkid',tar.id);
            sessionStorage.setItem('targetceng',tar.ceng);
            this.$store.commit('setitem',{'name':'checkid','item':tar.id})
            this.$store.commit('setitem',{'name':'outerId','item':outer.id});
            this.$store.commit('setitem',{'name':'dataLayer','item':tar.ceng});
            this.$router.push('/home/describe?pid=' + tar.pid);
        },
        See(c,over){
            let data = {
                c,
                over
            }
            this.$store.commit('setitem',{'name':'reportData','item':data})
            this.$store.commit('setitem',{'name':'reportShow','item':true})

            // this.$confirm('The test has been completed ! Could you view performance report?', 'Congratulations', {
            //     confirmButtonText: 'Yes',
            //     cancelButtonText: 'No',
            //     type: 'success',
            //     showClose:false,
            //     closeOnClickModal:false,
            // }).then(() => {
            //     if(over == 'over'){
            //         sessionStorage.setItem('temporary','over');
            //         this.$router.push('/home/table');
            //     }else{
            //         let tar = JSON.stringify(c);
            //         sessionStorage.setItem('temporary',tar);
            //         this.$router.push('/home/table');
            //     }
            // }).catch(() => {
            //     if(over == 'over'){
            //         this.$notify({
            //             title: 'Congratulations',
            //             message: 'Complete all chapters !',
            //             duration: 5000
            //         });
            //     }else{
            //         this.GoNext(c);
            //     }
            // });
        },
        layerTest(layer,dataStructure,type,pan){
            let str = 'dataStructure';
            let last = null;
            layer.forEach((el,i)=>{
                if(i<(layer.length-1)){
                    str += '.childList[' + el[1] + ']';
                }else{
                    last = el[1];
                }
            })
            let item = eval(str);

            if(type==0){
                if(last==0){
                    layer = layer.slice(0,(layer.length-1) );
                    let d = this.layerTest(layer,dataStructure,type,false);
                    return d;
                }else{
                    if(pan){
                        layer[layer.length-1][1] = last - 1;
                        return layer;
                    }else{
                        return layer;
                    }
                }
            }
            if(type==1){
                if(last == (item.alength-1)){
                    layer = layer.slice(0,(layer.length-1) );
                    let d = this.layerTest(layer,dataStructure,type);
                    return d;
                }else{
                    if(layer.length>0){
                        layer[layer.length-1][1] = parseInt(layer[layer.length-1][1]) + 1;
                        return layer;
                    }else{
                        return layer;
                    }
                }
            }

        },

        /*****                  获取 当前层 最外层数据                    *****/
        GetOutLayer(){
            let layer = sessionStorage.getItem('targetceng').split(',');
            layer = layer.map((el)=>{
                el = el.split('.');
                return el;
            })
            let item = this.menulist[layer[0][1]];
            return item;
        },
        /*****                  获取 当前层 最外层数据                    *****/


        /*****  滑块 音频播放  *****/
        // 重新 播放
        ResetTime(){
            // if(this.Pattern == '测试模式'){
            //     return;
            // }
            this.audioTime = 0;
            this.$refs.stemAudioPlayer && this.$refs.stemAudioPlayer.startPlayOrPause()
            // this.player.volume(1);
            // this.player.currentTime(0);
            this.InPlay = true;
        },
        // 滑块  播放时段改变
        TimeChange(){
            if(this.Pattern == '测试模式'){
                return;
            }
            let time = (this.audioTime / 100) *　this.player.duration();
            this.player.volume(1);
            this.player.currentTime(time);
            this.InPlay = true;
        },

        // flash 播放器 初始化
        FlashPlay(data){
            sessionStorage.setItem('PlayTwice','0'); // 播放次数 限制
            if(data==this.$store.state.common.AudioUrl){
                console.log('音频地址空!');
                return;
            }
            if(!!this.player){
                this.player.reset(); //重置 video
                this.player.src([
                    {
                        type:"rtmp/mp4",
                        src:data,
                    }
                ]);
                this.player.load();
                this.player.currentTime(0);
                this.player.play();
                return false;
            }
            let item = {
                width:'500',
                height:'500',
                controls:true,
                loop:false,
                preload:'auto',
                sources:[
                    {
                        type:"rtmp/mp4",
                        src:data,
                    }
                ],
            }
            window.CurrentUrl = data;
            this.player = videoJs('myElement',item);
            this.player.on('ready',()=>{
                if(this.BindRole.isAutoPaly == 1){
                    this.player.play();
                    this.InPlay = true;
                }else{

                }
            })
            this.player.on('canplaythrough',()=>{
                console.log('canplaythrough');
                let durationTime = this.player.duration();
                this.GetTime = true;
                this.playertime = window.ComputedTime(durationTime);
                if(this.BindRole.isAutoPaly == 1){
                    this.player.play();
                    this.InPlay = true;
                }else{

                }
            })
            this.player.on('timeupdate',()=>{
                let time = this.player.duration();
                if(time>0&&this.playertime==''){
                    this.GetTime = true;
                    this.playertime = window.ComputedTime(time);
                }
                let c = (this.player.currentTime() / this.player.duration()) * 100;
                this.audioTime = c;
            })

            this.player.on('waiting',()=>{
                console.log('waiting');
            })
            this.player.on('ended',()=>{
                this.InPlay = false;
                this.PlayerComplete();
            })
            this.player.on('seeked',()=>{
                console.log('时间跳动',this.player.currentTime());
            })
            this.player.on('error',(msg)=>{
                console.log('音频加载失败!',msg,window.CurrentUrl);
                let error = parseInt(sessionStorage.getItem('PlayError'));
                error++;
                if(error>3){
                    sessionStorage.setItem('PlayError',0);
                    if(this.Pattern == '测试模式'){
                        this.$message.error('音频加载时失败,进入下一题!');
                        this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                    }
                }else{
                    this.$message.error('音频加载时失败,重新加载播放器!');
                    sessionStorage.setItem('PlayError',error);
                    this.player.src([
                        {
                            type: 'rtmp/mp4',
                            src: window.CurrentUrl
                        },
                    ]);
                }
            })
            this.player.on('play',()=>{
                sessionStorage.setItem('PlayError',0);
                this.InPlay = true;
                console.log('主音频播放中');
            })
            this.player.on('pause',()=>{
                console.log('主音频暂停中');
                this.InPlay = false;
            })
        },
        /*****  滑块 音频播放  *****/
        PlayerComplete(){
            console.log('播放完成!');
        },
        /*****                  末子节点切换                    *****/
        PartPrev(UpLayerData){
            let layer = sessionStorage.getItem('targetceng');
            layer = layer.charAt(layer.length - 1);
            if(layer>0){
                let item = UpLayerData.childList[layer-1];
                this.PartGoToRouter(item);
            }
        },
        PartNext(UpLayerData){
            console.log(UpLayerData);

            let layer = sessionStorage.getItem('targetceng');
            layer = layer.charAt(layer.length - 1);
            let len = UpLayerData.childList.length;
            if(layer<(len-1)){
                let item = UpLayerData.childList[++layer];
                this.PartGoToRouter(item);
            }
        },
        PartGoToRouter(item){
            let studylog = JSON.parse(sessionStorage.getItem('studylog'));
            if(!!studylog&&studylog.length>0&&this.$store.state.common.MaxPattern == '闯关模式'){
                let pan = studylog.indexOf(item.id);
                if(pan<0){
                    this.$message.warning('尚未解锁!');
                    return;
                }
            }
            if(studylog.indexOf(item.id)<0){
                studylog.push(item.id);
                sessionStorage.setItem('studylog',JSON.stringify(studylog));
            }
            sessionStorage.setItem('checkid',item.id);
            sessionStorage.setItem('targetceng',item.ceng);
            this.$store.commit('setitem',{'name':'dataLayer','item':item.ceng})
            this.$store.commit('setitem',{'name':'checkid','item':item.id});
            this.$router.push('/home/describe?pid=' + item.id);
        },
        /*****                  末子节点切换                    *****/


        /*****                  末子节点切换 添加学习记录                   *****/
        async AddStudyLog(){
            return new Promise( async (resolve, reject) => {
                let item = this.Target;
                let targetId = item ? item.id  : this.targetId
                let addTbStudyLogId = sessionStorage.getItem('addTbStudyLogId')
                if(!addTbStudyLogId){
                    // sessionStorage.setItem("chapterStartTime",Date.parse(new Date()))
                    const dateRes=await this.AjaxApi.getSevDate();
                    sessionStorage.setItem("newChapterStartTime",Date.parse(dateRes.data))
                    sessionStorage.setItem("addTbStudyLogId",targetId)
                    resolve('init')
                    return
                } 
    
                // 挂机时间
                // let suspendTime = 0
                // let chapterSuspendTime = sessionStorage.getItem('chapterSuspendTime')
                // let chapterSuspendTimeEnd = sessionStorage.getItem('chapterSuspendTimeEnd') || Date.parse(new Date())
                // if(chapterSuspendTime ){
                //     suspendTime = chapterSuspendTimeEnd  - chapterSuspendTime
                // } 
                // let chapterStudyTime = Date.parse(new Date()) - sessionStorage.getItem('chapterStartTime')
                const nowDateRes=await this.AjaxApi.getSevDate();
                let chapterStudyTime = Date.parse(nowDateRes.data) - sessionStorage.getItem('newChapterStartTime');


                // sessionStorage.setItem("chapterStartTime",Date.parse(new Date()))
                if(targetId)
                    sessionStorage.setItem("addTbStudyLogId",targetId)
                // sessionStorage.removeItem("chapterSuspendTime")
                // sessionStorage.removeItem("chapterSuspendTimeEnd")
                
                // if(chapterStudyTime - suspendTime<1000){
                if(chapterStudyTime>1000){
                    //学习时长不足1s的记录不提交
                    let data = {
                        trainClassId:sessionStorage.getItem('trainClassId') || '',
                        courseId:this.$store.state.common.curriculum,
                        courseName:this.menulist[0].tbCourse && this.menulist[0].tbCourse.sourceName,
                        sectionAllName:'',
                        sectionId:addTbStudyLogId || targetId,
                        sectionName: '',
                        seatMealCourseId:sessionStorage.getItem('seatMealCourseId')||'abc',
                        uuid:sessionStorage.getItem('uuid'),
                        // chapterStudyTime:(Math.round(((chapterStudyTime - suspendTime)/1000))*1000),
                        chapterStudyTime:(Math.round(((chapterStudyTime)/1000))*1000),
                    } 
                    
                    if(!sessionStorage.getItem('trainClassId')) 
                        delete data.trainClassId
                        
                    await this.AjaxApi.AddStudyLog(data);
                    // 本地 学习记录存储
                    let studylog = sessionStorage.getItem('studylog');
                    studylog = JSON.parse(studylog);
                    if(studylog.indexOf(targetId)<0){
                        studylog.push(targetId);
                        sessionStorage.setItem('studylog',JSON.stringify(studylog));
                    }
                }
                resolve('ok')
            })
        },
        /*****                  末子节点切换 添加学习记录                   *****/

        /*****                  退出登陆                  *****/
        async SignOut(){
            this.$store.commit('setitem',{'name':'quitCourseShow','item':true});
        },
        async OutSide(outStudyShow=false){
            if(!outStudyShow)
                await this.AddStudyLog()
            let type = sessionStorage.getItem('usertype');
            let Utype = sessionStorage.getItem('type');
            let str = '';
            if(type=='1') str = 3;
            else str = Utype;
            let tartgt = this.$store.state.common.TargetItem;
            let AItem = {};
            if(!!tartgt.last){
                AItem = tartgt.data[tartgt.last];
            }else{
                AItem = tartgt.data;
            }
            // let data = {
            //     type:str,
            //     'courseId':this.$store.state.common.curriculum,
            //     'sectionId':AItem.id,
            //     'setmealCourseId':sessionStorage.getItem('setmealCourseId'),
            //     'enterpriseUserId':sessionStorage.getItem('enterpriseUserId'),
            // }
            // if(sessionStorage.getItem('trainClassId'))  data.trainClassId = sessionStorage.getItem('trainClassId')
            // let url_ = await this.AjaxApi.OutSide(data);
            if(AItem.id) {
                let data = {
                    type:str,
                    'courseId':this.$store.state.common.curriculum,
                    'sectionId':AItem.id,
                    'setmealCourseId':sessionStorage.getItem('setmealCourseId'),
                    'enterpriseUserId':sessionStorage.getItem('enterpriseUserId'),
                }
                if(sessionStorage.getItem('trainClassId'))  data.trainClassId = sessionStorage.getItem('trainClassId')
                let url_ = await this.AjaxApi.OutSide(data);
            }
            this.$store.commit('setWithdrawalStudy');

            sessionStorage.setItem('begin','');
            sessionStorage.setItem('alltime',0);
            sessionStorage.setItem('targetceng','');
            this.$store.commit('setitem',{'name':'quitCourseShow','item':false});
            this.$store.commit('setitem',{'name':'begin','item':false});
            this.$store.commit('setitem',{'name':'checkid','item':''});
            this.$store.commit('setitem',{'name':'outerId','item':''});
            this.$store.commit('setitem',{'name':'usertype','item': 3});

            if(this.$ipcRenderer){
                sessionStorage.clear()
                localStorage.clear()
                setTimeout(()=>{
                    this.$ipcRenderer.send("exit");
                },1500)
            }else{
                setTimeout(()=>{
                    window.location.href = 'http://' + window.location.host + sessionStorage.getItem('ROUTERPARAMS')
                },1500)
            }
        },
        /*****                  退出登陆                  *****/

        FlashUrl(src){
            let c = this.$store.state.common.AudioUrl + src;
            // let c = "http://192.168.1.214:7010/course/mp3/8593d765-d4d3-440d-b244-bcfcdc2117f1.mp3";
            return c;
        },
        AudioUrl(src){
            let c = this.$store.state.common.AudioUrlPublic + src;
            return c;
        },

        Clicktext(){
            this.mshow.text=2;
            if(this.mshow.notes!=0) this.mshow.notes = 1;
            if(this.mshow.key!=0) this.mshow.key = 1;
        },
        Clicknotes(){
            this.mshow.notes=2;
            if(this.mshow.text!=0) this.mshow.text = 1
            if(this.mshow.key!=0) this.mshow.key=1;
        },
        AudioEvent(){
            this.RecordeItem.Media.removeEventListener('error',this.AudioError);
            this.RecordeItem.Media.removeEventListener('canplay',this.AudioCanPlay);
            this.RecordeItem.Media.removeEventListener('ended',this.AudioEnded);
            this.RecordeItem.Media.addEventListener('ended',this.AudioEnded);
            this.RecordeItem.Media.addEventListener('canplay',this.AudioCanPlay);
            this.RecordeItem.Media.addEventListener('error',this.AudioError);
        },
        AudioError(){
            console.log('录音加载失败!');
            this.RecordeItem.RecorderAudio = '0';
        },
        AudioCanPlay(){
            console.log('可以播放录音!')
        },
        AudioEnded(){
            console.log('录音播放完成!');
            this.RecordeItem.MediaInPlay = false;
        },
        PlayChange(){
            this.RecordeItem.MediaInPlay = false;
        },
        // 获取分数
        async GetScore({answer,standardAnswer,type,url,bigTitleId,smallTitleId}){
            console.log(this.isJudgment,'this.isJudgment');

            if(this.isJudgment == '0') return false;
            // if(this.$store.state.common.isJudgment == '0') return false;
            this.ScoreShow = false
            this.TiScore  = '';
            let data = {
                answer:answer||'',
                standardAnswer:standardAnswer||'',
                type:type,
                uuid:sessionStorage.getItem('uuid'),
                courseId:sessionStorage.getItem('curriculum'),
                url:url||'',
                bigTitleId:bigTitleId,
                smallTitleId:smallTitleId,
                sectionId:this.Target.id,
            }
            let url_ = await this.AjaxApi.GetScore(data);
            if (url_.score == 1 || url_.score == 2) {
                this.TiScore = url_.score;
            } else if (url_.score == 3) {
                this.TiScore = '3-';
            } else if (url_.score == 4) {
                this.TiScore = '3+';
            } else if (url_.score == 5) {
                this.TiScore = '4-';
            } else if (url_.score == 6) {
                this.TiScore = '4+';
            } else if (url_.score == 7) {
                this.TiScore = '5-';
            } else if (url_.score == 8) {
                this.TiScore = '5+';
            }

            this.complate = true;
            this.ScoreShow = true;
            return url_;
        },
        // 生产uuid
        ProductUuid(){
            let id = '';
            for (let i = 0; i < 32; i++) {
                let c = Math.floor(Math.random()*10);
                id += c;
            }
            sessionStorage.setItem('uuid',id);
        },
        async StartStudy(){
            // let media = await navigator.mediaDevices.enumerateDevices();
            // let check = false;
            // media.forEach(el=>{
            //     if(el.kind=='audioinput'&&el.label!='默认'&&el.label!='通讯'){
            //         check = true;
            //     }
            // })
            // if(!check){
            //     // this.$message.warning('未检测到录音设备,请先安装设备在进行学习!');
            //     return false;
            // }

            //取消套餐，修改为永久课时
            let url_ = {
                data:1000*86400*366*1000
            }
            sessionStorage.setItem('usertype',3);
            this.$store.commit('setitem',{'name':'usertype','item':3});

            this.ProductUuid();
            sessionStorage.setItem('begin','true');
            this.$store.commit('setitem',{'name':'begin','item':true});
            this.$router.push('/home/describe');
        },
        TagReplace(){
            let reg = /<[^>]*>|<\/[^>]*>/gm;
            if(this.questionData.questionText){
                this.questionData.questionText = this.questionData.questionText.replace(reg,'');
            }
            if(!!this.childQuestionData.title&&!!this.childQuestionData.title.questionText){
                this.childQuestionData.title.questionText = this.childQuestionData.title.questionText.replace(reg,'');
            }
        },
        // text note key three show
        threeShow(role){
            let show = [];
            if(!!role.isReadOriginal&&role.isReadOriginal==1)show.push('TEXT');
            if(!!role.isRecordNote&&role.isRecordNote==1)show.push('NOTES');
            if(!!role.isReadAnswer&&role.isReadAnswer==1)show.push('KEY');
            if(!!role.isNewWordBook&&role.isNewWordBook==1)show.push('WORDLIST');
            if(!!role.isKnowledgeSearch&&role.isKnowledgeSearch==1)show.push('KNOWLEDGESEARCHING');
            if(!!role.isExtend&&role.isExtend==1)show.push('EMPHASIS');
            this.$store.commit('setitem',{name:'threeList',item:show});
            this.$store.commit('setitem',{name:'threeActive',item:''});
        },
        threeShowClose(){
            this.$store.commit('setitem',{name:'threeActive',item:''});
        },
        NoteFocus(){
            window.onkeydown = function(){

            }
            window.onkeypress = function(){

            }
        },
        NoteBlur(){
            console.log('vvv');

            window.onkeydown = function(){
                let d = window.event.keyCode;
                console.log(d);

                if(d==8){
                    return false;
                }
            }
            window.onkeypress = function(){
                let d = window.event.keyCode;
                console.log(d);
                if(d==8){
                    return false;
                }
            }
        },
        // 录音播放完成
        recorderPlayerAudioEndedOver(){
            this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay
        },
        //
        changeListeningTextStatus(v){
            if(v){
                this.status = 'ListeningText'
            }
        }
    },
})
